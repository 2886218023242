<template>
  <table :style="{width: showCards ? '750px' : '750px'}">
    <tbody>
    <tr>
      <td

        :colspan="datasource[childrenField] && datasource[childrenField].length ? datasource[childrenField].length*2 : null"
      >

        <!--                <div class="function-arrow" v-if="datasource.function_chief">&#45;&#45;&#45;&#45;&#45;&#45;</div>-->
        <div class="node" :id="datasource.id" @click.stop="handleClick(datasource)">
          <slot :node-data="datasource">
            <div
              class="node-big-block"
            >

              <div
                v-if="showChiefFunctional && datasource.chief_functional"
                class="node-fr-block"
                @click.stop="handleClick(datasource)"
              >
                <div class="node-block-function">
                  <div class="node-block-function-title">Функциональный руководитель</div>
                  <div class="node-block-image" v-if="showImage">
                    <img width="100%"
                         :src="'http://evraz.dev.knuateam.ru/data/avatars' + (datasource.function_chief.avatar ? datasource.function_chief.avatar : '')"
                         @error="setDefaultImage"/>

                  </div>
                  <div class="node-block-function-fio">{{ datasource.chief_functional[fioField] }}</div>
                  <div class="node-block-position" v-if="datasource.chief_functional[positionField]">
                    {{ datasource.chief_functional[positionField] }}
                  </div>
                  <div class="node-block-position" v-if="datasource.chief_functional.company_division">
                    {{ datasource.chief_functional.company_division }}
                  </div>
                  <div class="node-block-position" v-if="datasource.meta">
                    {{ datasource.meta.org_shortname}}
                  </div>
                </div>
              </div>



              <div
                class="node-block"
                :class="{active: currentUserId===datasource.id}"
              >
                <el-button
                  :id="'orgstruct-tree-up-'+datasource.id"
                  style="margin-bottom:5px;"
                  v-show="!renderedFromParent && datasource.direct_chief_id"
                  size="mini"
                  icon="fas fa-arrow-up"
                  @click="$emit('load-parent', {datasource:datasource, event:$event})"
                >
                  Вверх
                </el-button>
                <div>
                  <div
                    v-if="showImage"
                    class="node-block-image"
                  >
                    <img width="100%"
                         :src="'http://evraz.dev.knuateam.ru/data/avatars'+(datasource.avatar ? datasource.avatar : '')"
                         @error="setDefaultImage"/>

                  </div>
                  <div class="node-block-fio">{{ datasource[fioField] }}</div>
                  <div class="node-block-position">{{ datasource[positionField] }}</div>
                  <div class="node-block-position">{{ datasource.company_division }}</div>
                  <div class="node-block-position">{{ datasource.meta ? datasource.meta.org_shortname : '' }}</div>
                </div>


                <div>
                  <el-button
                    v-if="datasource.chief_functional"
                    size="mini"
                    :type="currentUserId===datasource.id ? 'primary' : 'info'"
                    :icon="showChiefFunctional ? 'fas fa-arrow-right' : 'fas fa-arrow-left'"
                    @click="toggleChiefFunctional"
                  >
                    <span v-if="showChiefFunctional">Скрыть ФР</span>
                    <span v-else>ФР</span>
                  </el-button>
                  <el-button
                    v-show="!datasource[childrenField] || !datasource[childrenField]"
                    :id="'orgstruct-tree-down-'+datasource.id"
                    style="margin-left:0"
                    :type="currentUserId===datasource.id ? 'primary' : 'info'"
                    size="mini"
                    icon="fas fa-arrow-down"
                    @click="$emit('load-child', {datasource:datasource, event:$event})"
                  >
                    Вниз
                  </el-button>
                  <el-button
                    v-show="datasource[childrenField] && datasource[childrenField].length"
                    :id="'orgstruct-tree-hide-'+datasource.id"
                    style="margin-left:0"
                    :type="currentUserId===datasource.id ? 'primary' : 'info'"
                    size="mini"
                    icon="fas fa-arrow-up"
                    @click="$emit('remove-child', {datasource:datasource, event:$event}); removeChild(datasource);"
                  >
                    Скрыть
                  </el-button>
                </div>
              </div>

              <div
                v-if="showCards && datasource.card_elements !== null"
                class="node-card-block"
                :class="{active: currentUserId===datasource.id}"
                @wheel.stop
              >
                <el-table
                  max-height="460"
                  empty-text="Нет КПЭ"
                  :data="datasource.card_elements"
                  size="mini"
                  border
                  style="width: 100%"
                >
                  <el-table-column prop="name" label="Наименование"></el-table-column>
                  <el-table-column prop="scale.weight_plan" label="Вес,%" width="70"></el-table-column>
                </el-table>
              </div>
            </div>
          </slot>
        </div>
      </td>
    </tr>
    <template v-if="datasource[childrenField] && datasource[childrenField].length">
      <tr class="lines">
        <td :colspan="datasource[childrenField].length*2">
          <div class="downLine"></div>
        </td>
      </tr>
      <tr class="lines">
        <td class="rightLine"></td>
        <template v-for="n in (datasource[childrenField].length-1)">
          <td class="leftLine topLine"></td>
          <td class="rightLine topLine"></td>
        </template>
        <td class="leftLine"></td>
      </tr>
      <tr class="nodes">
        <td colspan="2" v-for="child in datasource[childrenField]" :key="child.id">
          <orgstruct-tree-node
            renderedFromParent
            :datasource="child"
            :children-field="childrenField"
            :position-field="positionField"
            :fio-field="fioField"
            :handle-click="handleClick"
            :show-cards="showCards"
            :toggle-show-functional-chief="toggleShowFunctionalChief"
            :current-user-id="currentUserId"
            @load-parent="$emit('load-parent', $event)"
            @load-child="$emit('load-child', $event)"
            @remove-child="$emit('remove-child', $event)"
          >
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
              <slot :name="slot" v-bind="scope"/>
            </template>
          </orgstruct-tree-node>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'orgstruct-tree-node',
  props: {
    datasource: Object,
    handleClick: Function,
    childrenField: {type: String, default: 'children'},
    positionField: {type: String, default: 'position'},
    fioField: {type: String, default: 'name'},
    renderedFromParent: {type: Boolean, default: false},
    showImage: {type: Boolean, default: false},
    showCards: {type: Boolean, default: false},
    toggleShowFunctionalChief: {type: Boolean, default: false},
    currentUserId: {type: Number},
  },
  created() {
    this.datasource.show_chief_functional = true;
  },
  watch: {
    toggleShowFunctionalChief: function (newVal) {
      this.showChiefFunctional = newVal;
    }
  },
  data(){
    return {
      showChiefFunctional: true,
    }
  },
  methods: {
    setDefaultImage(e) {
      // e.target.src = require("@/assets/img/no_user_photo.jpg");
      e.target.src = require("@/assets/img/no_user_photo_2.jpg");
    },

    removeChild(ds) {
      ds[this.childrenField] = null;
    },

    toggleChiefFunctional(){
      this.showChiefFunctional = !this.showChiefFunctional;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/project-variables.scss";
$inactive-color: rgb(144,147,153);

.orgchart-container button span {
  pointer-events: none;
}

.orgchart .node {
  width: 150px;
}

.node-big-block{
  display: flex;
}
.node-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 150px;
  border: 2px solid $inactive-color;
  background-color: white;
  height: 460px;

  &.active {
    border-color: $--color-primary;
    background-color: color-mix(in srgb, $inactive-color 6%, white);

    .node-block-fio{
      background-color: color-mix(in srgb, $--color-primary 6%, white);
      color: $--color-primary;
    }

    .node-block-position{
      background-color: color-mix(in srgb, $inactive-color 6%, white);
    }
  }
}

.node-block button {
  width: 100%;
}

.node-block-image {
  display: flex;
}

.node-block-fio {
  background-color: white;
  color: black;
  padding: 5px;
}

.node-block-position {
  font-size: 0.8rem;
  padding: 5px;
  background-color: white;
  color: $inactive-color;
}


.orgchart .lines .topLine {
  border-top: 2px solid $inactive-color;
}

.orgchart .lines .rightLine {
  border-right: 1px solid $inactive-color;
  float: none;
  border-radius: 0;
}

.orgchart .lines .leftLine {
  border-left: 1px solid $inactive-color;
  float: none;
  border-radius: 0;
}

.orgchart .lines .downLine {
  background-color: $inactive-color;
  margin: 0 auto;
  height: 20px;
  width: 2px;
  float: none;
}


.node-fr-block{
  margin-left: -160px;
}

.node-block-function {
  border: 2px solid $inactive-color;
  border-right: 0px;
  height: 460px;
  background-color: white;
}

.node-block-function-title {
  background-color: $inactive-color;
  color: white;
  padding: 5px;
}

.node-block-function-fio {
  background-color: white;
  padding: 5px;
}

.function-arrow {
  display: inline-block;
  margin-bottom: 50%;
}


.node-card-block{
  border: 2px solid $inactive-color;
  border-left: 0;
  flex: 0 0 400px;
  background-color: white;
  overflow: auto;

  &.active {
    border-color: $--color-primary;
  }
}
</style>